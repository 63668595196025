<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/List"
        :keyID="mid ? '' : '932D2690D245D99848100740DB2A9FCA'"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :header="header"
        :footer="footer"
        @on-ok="$emit('on-ok',$event)"
        :multiColumn="multiColumn"
        :detailInner="detailInner"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="隐患等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="隐患等级" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="整改状态" prop="status">
          <Select v-model="params.status" clearable placeholder="整改状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['隐患状态']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="更新开始时间" prop="UpdatedStartAt">
          <ys-date-picker type="daterange" v-model="params.UpdatedStartAt" clearable style="width: 120px" placeholder="更新开始时间" @on-change="deteChange"></ys-date-picker>
        </FormItem> -->
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn" v-if="amendId<=0">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="midData.length===1&&(midData[0].status!==5||!!midData[0].amendPublishedAt)" v-if="mid && !assembly">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID" :checkType="checkType" :tableHeight="tableHeight"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" :title="(tableSelectItem.length>0?'修改':'新增')+'风险'">
      <Edit @on-ok="getList" :parentID="mid" :midData="midData.length > 0 ? midData : tableSelectItem" :mid="detailID"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Edit from "./Edit.vue";
import Detail from "./detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    detailInner: {type: Boolean, default: false},
    assembly: {type: Boolean, default: false}, // 是否作为组件来使用
    type: {type: String, default: ''},
    amendId: {type: [Number, String], default: null},
    projectId: {type: [Number, String], default: null},
    categoryId: {type: [Number, String], default: null},
    dictId: {type: [Number, String], default: null},
    itemId: {type: [Number, String], default: null},
    amendType: {type: [Number], default: null},
    amendStatus: {type: [Number], default: null},
    status: {type: [Number], default: null},
    riskGrade: {type: [Number], default: null},
    checkType: {type: Number, default: 2}, // 任务类型
  },
  components: {
    Edit,
    Detail,
  },
  data() {
    return {
      checkFlag: false,
      params: {
        checkId: '',
        riskGrade: '',
        type: '',
        amendId: '',
        projectId: '',
        categoryId: '',
        dictId: '',
        itemId: '',
        amendType: '',
        amendStatus: '',
        status: '',
        // keyword: '',
        UpdatedStartAt: '',
        UpdatedEndAt: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 100, title: '整改状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {width: 100, title: '整改单状态', key: 'amendStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '整改单状态'}},
        {minWidth: 150, title: '隐患项目', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 150, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 150, title: '所属检查项', key: 'categoryName', align: 'left'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {width: 170, title: '发现时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {
          width: 180, title: '风险辨别(扣分项)', key: 'scope', align: 'center', render: (h, params) => {
            return h('span', String(params.row.scope === -1 ? '-' : params.row.scope))
          }
        },
        {width: 100, title: '隐患等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '隐患描述', tooltip: true, key: 'remarksText', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      if (this.mid && !this.assembly) {
        return [
          {
            hiddenBtnIcon: true,
            children: [
              {
                type: 'detail',
                title: '查看详情',
                icon: 'md-eye',
                disabled: this.tableSelectItem.length !== 1
              },
              {
                click: () => this.modShow = true,
                title: '编辑',
                icon: 'md-create',
                iconColor: '#19be6b',
                disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5) || this.tableSelectItem[0].amendStatus === 2
              },
              {
                click: () => this.delItem('/comment/Remove'),
                iconColor: '#ed4014',
                title: '删除',
                icon: 'md-trash',
                disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5) || this.tableSelectItem[0].amendStatus === 2
              },
            ]
          }
        ]
      } else {
        // 非组件时，列表中数据不全(有些数据只有任务才有)暂时先去除编辑和删除功能
        return [
          {
            hiddenBtnIcon: true,
            children: [
              {
                type: 'detail',
                title: '查看详情',
                icon: 'md-eye',
                disabled: this.tableSelectItem.length !== 1
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.params.checkId = this.mid || '';
    this.params.type = this.type || '';
    this.params.amendId = this.amendId || '';
    this.params.projectId = this.projectId || '';
    this.params.categoryId = this.categoryId || '';
    this.params.dictId = this.dictId || '';
    this.params.itemId = this.itemId || '';
    this.params.amendType = this.amendType || '';
    this.params.amendStatus = this.amendStatus || '';
    this.params.status = this.status || '';
    this.params.riskGrade = this.riskGrade || '';
    this.getList()
  },
  methods: {
    // deteChange(date) {
    //   this.params.UpdatedStartAt = date[0]
    //   this.params.UpdatedEndAt = date[1]
    //   this.params.status = 2
    // },
  }
}
</script>
